import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  SIDEBAR_TILES,
  SIDEBAR_BREAKPOINT,
} from "../constants/sidebarConstants";
import { getAuth } from "../utils/common";

const Sidebar = () => {
  const location = useLocation();
  const [openTab, setOpenTab] = useState(null);
  const [userType, setUserType] = useState(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const toggleTab = (tab) => {
    setOpenTab(openTab === tab ? null : tab);
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const getActiveClass = (path) => {
    return location.pathname === path
      ? "bg-gray-500 border-l-4 border-blue-500"
      : "";
  };

  const handleLinkClick = () => {
    if (isMobile) {
      setIsSidebarOpen(false);
    }
  };

  useEffect(() => {
    const auth = getAuth();
    const user = JSON.parse(auth);
    setUserType(user?.roles[0]);

    const handleResize = () => {
      setIsMobile(window.innerWidth < SIDEBAR_BREAKPOINT);
      if (window.innerWidth >= SIDEBAR_BREAKPOINT) setIsSidebarOpen(true);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="max-h-screen overflow-y-scroll fixed bg-gray-800 z-[9999]">
      {isMobile && !isSidebarOpen && (
        <button
          className="lg:hidden p-3 fixed top-0 left-0 z-50"
          onClick={toggleSidebar}
        >
          <span className="bg-gray-800 text-white p-2 rounded">☰</span>
        </button>
      )}

<div
        className={`fixed top-0 left-0 h-screen w-64 bg-gray-800 text-white z-50 transform ${
          isSidebarOpen ? "translate-x-0" : "-translate-x-full"
        } transition-transform duration-300 ease-in-out lg:translate-x-0 lg:relative lg:w-64 max-h-screen overflow-y-auto`}
        style={{ boxShadow: isMobile && isSidebarOpen ? "0 0 10px rgba(0, 0, 0, 0.8)" : "" }}
      >
        <div className="p-4 text-2xl font-bold">Internal App</div>
        <nav className="mt-6">
          <ul>
          {SIDEBAR_TILES.map((tile, index) => {
  if (tile.children) {
    return (
      <li key={index} className="p-4 hover:bg-gray-500">
        <div
          onClick={() => toggleTab(tile.title)}
          className="cursor-pointer flex items-center justify-between w-full"
        >
          {tile.title}
        </div>
        {openTab === tile.title && (
          <ul className="pl-4">
            {tile.children.map((child, childIndex) => (
              <li
                key={childIndex}
                className={`p-4 hover:bg-gray-700 ${getActiveClass(
                  child.path
                )}`}
              >
                <Link to={child.path} onClick={handleLinkClick}>
                  {child.name}
                </Link>
              </li>
            ))}
          </ul>
        )}
      </li>
    );
  }

  if (tile.visibleTo && tile.visibleTo !== userType) return null;

  return (
    <li
      key={index}
      className={`p-4 hover:bg-gray-700 ${getActiveClass(tile.path)}`}
    >
      <Link
        to={tile.path}
        className="flex items-center justify-between w-full"
        onClick={handleLinkClick}
      >
        {tile.name}
      </Link>
    </li>
  );
})}

          </ul>
        </nav>
      </div>

      {isSidebarOpen && isMobile && (
        <div
          className="fixed top-0 left-0 w-full h-full bg-black opacity-50 z-40"
          onClick={toggleSidebar}
        ></div>
      )}
    </div>
  );
};

export default Sidebar;