
export const LOCALSTORAGE_AUTH = "auth";

export const AuthService = {
  async getToken() {
    try {
      const authStr = await localStorage.getItem(LOCALSTORAGE_AUTH);
      if (!authStr) return "";

      const auth = JSON.parse(authStr);
      return auth?.token ?? "";
    } catch (err) {
      console.error(err);
      return "";
    }
  },
  setToken(response) {
    localStorage.setItem(LOCALSTORAGE_AUTH, response);
  },
};
