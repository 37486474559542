import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getMigrationDetails, verifyMigration, cleanupMigration } from '../api.js';

const Migration = () => {
  const [requestID, setRequestID] = useState('');
  const [migrationDetails, setMigrationDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleVerify = async () => {
    const isVerified = await verifyMigration(requestID);
    if (isVerified) {
      toast.success("Verified");
    } else {
      toast.error("Verification Failed");
    }
  };

  const handleCleanup = async () => {
    const response = await cleanupMigration(requestID);
    if (response) {
      toast.success("Cleaned");
    } else {
      toast.error("Cleanup Failed");
    }
  };

  const fetchMigrationDetails = async () => {
    if (!requestID) {
      setError('Please enter a valid request ID');
      return;
    }

    setLoading(true);
    setError('');
    try {
      const data = await getMigrationDetails(requestID);
      setMigrationDetails(data);
    } catch (err) {
      setError('Failed to fetch migration details');
    }
    setLoading(false);
  };

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col items-center justify-center p-4">
      <div className="bg-white shadow-lg rounded-lg p-6 w-full">
        <h1 className="text-2xl font-semibold text-center text-gray-800 mb-6">
          Migration Request Details
        </h1>

        <input
          type="text"
          placeholder="Enter Migration Request ID"
          value={requestID}
          onChange={(e) => setRequestID(e.target.value)}
          className="w-full p-3 border border-gray-300 rounded mb-4 focus:outline-none focus:ring-2 focus:ring-blue-500"
        />

        <button
          onClick={fetchMigrationDetails}
          className="w-full bg-blue-600 text-white font-semibold py-2 rounded hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 transition"
        >
          {loading ? 'Loading...' : 'Get Migration Details'}
        </button>

        {error && <p className="text-red-500 text-center mt-4">{error}</p>}

        {migrationDetails && (
          <div className="mt-6 bg-gray-50 p-4 rounded border border-gray-200">
            <h2 className="text-xl font-semibold mb-2 text-gray-800">Migration Details</h2>
            <div className="text-sm text-gray-700">
              <p><strong>Request ID:</strong> {migrationDetails.requestId}</p>
              <p><strong>Migration State:</strong> {migrationDetails.migrationState}</p>
              <h3 className="text-lg font-semibold mt-4">Store Details</h3>
              <p><strong>Store ID:</strong> {migrationDetails.storeDetails.storeId}</p>
              <p><strong>Operator ID:</strong> {migrationDetails.storeDetails.operatorId}</p>
              <p><strong>Name:</strong> {migrationDetails.storeDetails.name}</p>
              <p><strong>Location:</strong> {migrationDetails.storeDetails.location.city}, {migrationDetails.storeDetails.location.state}</p>
              <p><strong>Amenities:</strong> {migrationDetails.storeDetails.amenities.amenityTypes.join(', ')}</p>
              
              <h3 className="text-lg font-semibold mt-4">Images</h3>
              <ul className="list-disc list-inside">
                {migrationDetails.storeDetails.images.map((image, index) => (
                  <li key={index}>
                    <p><strong>Image ID:</strong> {image.imageId}</p>
                  </li>
                ))}
              </ul>

              <h3 className="text-lg font-semibold mt-4">Charging Stations</h3>
              {migrationDetails.chargingStations.map((station, index) => (
                <div key={index} className="mt-4">
                  <p><strong>Station ID:</strong> {station.chargingStationId}</p>
                  <p><strong>Latitude:</strong> {station.latitude}</p>
                  <p><strong>Longitude:</strong> {station.longitude}</p>
                  <h4 className="text-md font-semibold mt-2">EVSE Details</h4>
                  {station.evseDetails.map((evse, idx) => (
                    <div key={idx} className="ml-4">
                      <p><strong>EVSE ID:</strong> {evse.evseId}</p>
                      {evse.connectorDetails.map((connector, i) => (
                        <div key={i} className="ml-4">
                          <p><strong>Connector ID:</strong> {connector.connectorId}</p>
                          <p><strong>Type:</strong> {connector.type}</p>
                          <p><strong>Power Rating (kWh):</strong> {connector.powerRatingKWH}</p>
                          <p><strong>Price:</strong> {connector.price.rate} {connector.price.unit}</p>
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
        )}

        {/* Show buttons only when migration details are loaded */}
        {migrationDetails && (
          <div className="p-4">
            <div className="flex justify-center space-x-4">
              <button
                onClick={handleVerify}
                className="px-8 py-2 mx-2 bg-blue-600 text-white font-semibold rounded hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 transition"
              >
                Verify
              </button>
              <button
                onClick={handleCleanup}
                className="px-6 py-2 mx-2 bg-red-600 text-white font-semibold rounded hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 transition"
              >
                CleanUp
              </button>
            </div>
          </div>
        )}
      </div>
      <ToastContainer />
    </div>
  );
};

export default Migration;
