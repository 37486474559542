import { ROUTES } from "../routes";

export const SIDEBAR_TILES = [
  {
    title: "Model",
    children: [
      { name: "Add Model", path: `/${ROUTES.ADD_VEHICLE}` },
      { name: "Model List", path: `/${ROUTES.MODEL_LIST}` },
    ],
  },
  {
    title: "Store",
    children: [
      { name: "Store Details", path: `/${ROUTES.STORE_DETAILS}` },
      { name: "Store Search", path: `/${ROUTES.STORE_SEARCH}` },
      { name: "Add Store", path: `/${ROUTES.ADD_STORE}` },
      { name: "Charging Stations", path: `/${ROUTES.CHARGING_STATION}` },
    ],
  },
  { name: "Home", path: `/${ROUTES.HOME}` },
  { name: "Create Business", path: `/${ROUTES.CREATE_BUSINESS}` },
  { name: "QR Resolve", path: `/${ROUTES.QR_RESOLVE}` },
  { name: "Booking Details", path: `/${ROUTES.BOOKING_DETAILS}` },
  { name: "Operator List", path: `/${ROUTES.OPERATOR}` },
  { name: "Migration Details", path: `/${ROUTES.MIGRATION}` },
  { name: "Location", path: `/${ROUTES.PLACES}` },
  {
    name: "HealthCheck",
    path: `/${ROUTES.HEALTH_CHECK}`,
    visibleTo: "INTERNAL_ADMIN", 
  },
];

export const SIDEBAR_BREAKPOINT = 1024;
