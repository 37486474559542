import React, { useState } from 'react';
import { fetchStoreDetails } from '../api'; 
import StoreList from '../components/StoreList';

const StoreDetails = () => {
  const [storeId, setStoreId] = useState('');
  const [storeDetails, setStoreDetails] = useState(null); 
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleFetchStoreDetails = async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await fetchStoreDetails(storeId);
      setStoreDetails(response); 
    } catch (err) {
      console.error('Error fetching store details:', err);
      setError('Error fetching store details. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col items-center p-6 max-w-md mx-auto bg-white shadow-md rounded-md">
      <h2 className="text-2xl font-semibold mb-4">Store Details</h2>
      <input
        type="text"
        placeholder="Enter Store ID"
        value={storeId}
        onChange={(e) => setStoreId(e.target.value)}
        className="mb-4 p-2 w-full border border-gray-300 rounded-md"
      />
      <button
        onClick={handleFetchStoreDetails}
        className="px-4 py-2 bg-blue-600 text-white font-semibold rounded-md hover:bg-blue-700"
        disabled={!storeId || loading}
      >
        {loading ? 'Loading...' : 'Fetch Store Details'}
      </button>

      {error && <p className="text-red-500 mt-4">{error}</p>}

      {storeDetails && <StoreList data={storeDetails} />}
    </div>
  );
};

export default StoreDetails;
