import React, { useState, useRef } from 'react';
import { Upload, Camera } from 'lucide-react';
import Webcam from 'react-webcam';
import { useNavigate } from 'react-router-dom';
import { getPresignedUrl, verifyUpload } from '../api'; 
import axios from 'axios';
import { useAppContext } from "../Context";
import { ROUTES } from "../routes";

const ImageUploadButton = ({  onUploadComplete }) => {
  const [isUploading, setIsUploading] = useState(false);
  const [preview, setPreview] = useState(null);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [showCamera, setShowCamera] = useState(false);
  const navigate = useNavigate();
  const webcamRef = useRef(null);
  const {storeData}=useAppContext();

  const uploadToS3 = async (presignedUrl, file) => {
    const parsedUrl = new URL(presignedUrl);
    const queryParams = parsedUrl.searchParams;
    
    const headers = {
      'x-amz-algorithm': queryParams.get('X-Amz-Algorithm'),
      'x-amz-date': queryParams.get('X-Amz-Date'),
      'x-amz-signedheaders': queryParams.get('X-Amz-SignedHeaders'),
      'x-amz-expires': queryParams.get('X-Amz-Expires'),
      'x-amz-credential': queryParams.get('X-Amz-Credential'),
      'x-amz-signature': queryParams.get('X-Amz-Signature'),
      'Content-Type': file.type,
    };

    try {
      await axios.put(parsedUrl.origin + parsedUrl.pathname, file, { headers });
    } catch (error) {
      throw new Error('Failed to upload to S3');
    }
  };

  const handleFileSelect = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    if (!file.type.startsWith('image/')) {
      setError('Please select an image file');
      return;
    }

    if (file.size > 5 * 1024 * 1024) {
      setError('File size should be less than 5MB');
      return;
    }

    const reader = new FileReader();
    reader.onloadend = () => setPreview(reader.result);
    reader.readAsDataURL(file);

    setError(null);
  };

  const handleCapture = () => {
    const imageSrc = webcamRef?.current.getScreenshot();
    if (!imageSrc) {
      setError('Failed to capture image');
      return;
    }
    setPreview(imageSrc);
    setShowCamera(false);
    setError(null);
  };

  const confirmUpload = async () => {
    setIsUploading(true);
    setError(null);
    setSuccessMessage(null);

    try {
      const blob = await (await fetch(preview)).blob();
      const { imageId, presignedImageUploadS3Url } = await getPresignedUrl(
        "ONBOARDING",
        storeData?.requestId,
        storeData?.storeId,
      );

      await uploadToS3(presignedImageUploadS3Url, blob);
      await verifyUpload(storeData?.storeId, imageId);

      setSuccessMessage('Image successfully added!');
      if (onUploadComplete) {
        onUploadComplete(imageId);
      }
    } catch (error) {
      console.error('Upload process failed:', error);
      setError(error.message);
    } finally {
      setIsUploading(false);
      setPreview(null);
    }
  };

  const clearPreview = () => {
    setPreview(null);
    setError(null);
  };

  const addAnotherImage = () => {
    setPreview(null);
    setSuccessMessage(null);
    setError(null);
    setShowCamera(false);
  };

  return (
    <div className="w-full max-w-md">
      {showCamera ? (
        <div className="relative">
          <Webcam
            audio={false}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            className="w-full h-48 object-cover"
          />
          <button
            onClick={handleCapture}
            className="mt-4 p-2 bg-blue-500 text-white rounded-lg"
          >
            Capture Photo
          </button>
          <button
            onClick={() => setShowCamera(false)}
            className="mt-2 p-2 bg-gray-500 text-white rounded-lg"
          >
            Cancel
          </button>
        </div>
      ) : (
        <>
          {!preview && !successMessage ? (
            <div className="flex justify-between items-center gap-4">
              <div className="relative">
                <input
                  type="file"
                  onChange={handleFileSelect}
                  accept="image/*"
                  className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                  disabled={isUploading}
                />
                <div className="border-2 border-dashed border-gray-300 rounded-lg p-6 text-center hover:border-blue-500 transition-colors">
                  <Upload className="mx-auto h-12 w-12 text-gray-400" />
                  <p className="mt-2 text-sm text-gray-600">
                    {isUploading ? 'Uploading...' : 'Upload from Gallery'}
                  </p>
                </div>
              </div>
              <button
                onClick={() => setShowCamera(true)}
                className="border-2 border-dashed border-gray-300 rounded-lg p-6 text-center hover:border-blue-500 transition-colors"
              >
                <Camera className="mx-auto h-12 w-12 text-gray-400" />
                <p className="mt-2 text-sm text-gray-600">Take Photo</p>
              </button>
            </div>
          ) : (
            <div className="relative">
              {preview && (
                <>
                  <img
                    src={preview}
                    alt="Preview"
                    className="w-full h-48 object-cover"
                  />
                  <div className="flex justify-between mt-2">
                    <button
                      onClick={confirmUpload}
                      className="p-2 bg-green-500 text-white rounded-lg"
                      disabled={isUploading}
                    >
                      Confirm
                    </button>
                    <button
                      onClick={clearPreview}
                      className="p-2 bg-gray-500 text-white rounded-lg"
                      disabled={isUploading}
                    >
                      Retake
                    </button>
                  </div>
                </>
              )}
              {successMessage && (
                <div className="mt-4 text-center">
                  <p className="text-green-600">{successMessage}</p>
                  <button
                    onClick={addAnotherImage}
                    className="mt-2 p-2 bg-blue-500 text-white rounded-lg"
                  >
                    Add Another Image
                  </button>
                  <button
                    onClick={() => {
                      navigate(`/${ROUTES.HOME}`);
                    }}
                    className="mt-2 p-2 bg-blue-500 text-white rounded-lg"
                  >
                    Done Uploading the Image
                  </button>
                </div>
              )}
            </div>
          )}
        </>
      )}

      {error && <p className="mt-2 text-sm text-red-600">{error}</p>}
    </div>
  );
};

export default ImageUploadButton;
