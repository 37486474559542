import { AuthService } from "./AuthService";

const baseUrl = 'https://api.chargepe.io/v1';

const apiInstance = async ({
  endpoint,
  method = "GET",
  body = null,
}: {
  endpoint: string;
  method?: string;
  body?: any;
}) => {
  const token = await AuthService.getToken();
  const options: RequestInit = {
    method,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,  
    },
  };

  if (body) {
    options.body = JSON.stringify(body);
  }

  try {
    const response = await fetch(baseUrl + endpoint, options);
    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.message || "Unknown error occurred"); // Create a new Error instance
    }
    const data = await response.json();
    return data;
  } catch (error) {
    // Ensure the thrown error is an instance of Error
    throw error instanceof Error ? error : new Error("Something went wrong");
  }
};

export default apiInstance;
