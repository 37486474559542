import React, { useState } from 'react';
import { resolveQR } from '../api'; 

const QRResolve = () => {
  const [chargerId, setChargerId] = useState('');
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleResolveQR = async () => {
    setLoading(true);
    setError(null);

    try {
      const resolvedData = await resolveQR(chargerId);
      setData(resolvedData);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const formatSlotDetails = (slotDetails) => {
    if (!slotDetails) return 'N/A';
    return `Start: ${slotDetails.startTime.hourOfDay}:${slotDetails.startTime.minuteOfHour} ${slotDetails.startTime.ampm}, 
      End: ${slotDetails.endTime.hourOfDay}:${slotDetails.endTime.minuteOfHour} ${slotDetails.endTime.ampm}, 
      Charge Estimate: ${slotDetails.chargeAddEstimate}%, 
      Charge Upper Limit: ${slotDetails.chargeUpperLimit}%`;
  };

  const formatFulfillment = (fulfillmentContext) => {
    if (!fulfillmentContext) return 'N/A';
    return `Fulfillment ID: ${fulfillmentContext.fulfillmentId}, 
      Units: ${fulfillmentContext.units} kWh, 
      Amount: ₹${(fulfillmentContext.amountPaisa / 100).toFixed(2)}, 
      Start Time: ${fulfillmentContext.startTime.hourOfDay}:${fulfillmentContext.startTime.minuteOfHour} ${fulfillmentContext.startTime.ampm}, 
      End Time: ${fulfillmentContext.endTime.hourOfDay}:${fulfillmentContext.endTime.minuteOfHour} ${fulfillmentContext.endTime.ampm}`;
  };

  return (
    <div className="p-4">
      <div className="mb-4">
        <label className="block text-gray-700 font-bold mb-2">Please enter Charger ID:</label>
        <input
          type="text"
          value={chargerId}
          onChange={(e) => setChargerId(e.target.value)}
          className="border border-gray-300 p-2 rounded w-full"
          placeholder="Enter Charger ID"
        />
      </div>

      <button
        onClick={handleResolveQR}
        className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 focus:outline-none"
      >
        Enter
      </button>

      {loading && <div className="mt-4 text-blue-600">Loading...</div>}
      {error && <div className="mt-4 text-red-500">{error}</div>}

      {data && (
        <div className="mt-4 p-4 bg-gray-100 rounded shadow-md">
          <h2 className="text-lg font-semibold mb-2">QR Details</h2>

          <div className="mb-4">
            <img src={data.connectorFulfillmentDetails.operatorLogoUrl} alt="Operator Logo" className="mb-2 w-20 h-auto"/>
            <p><strong>Operator:</strong> {data.connectorFulfillmentDetails.operatorName}</p>
            <p><strong>Network:</strong> {data.connectorFulfillmentDetails.network}</p>
            <p><strong>Year Active Since:</strong> {data.connectorFulfillmentDetails.yearActiveSince}</p>
            <p><strong>Store Name:</strong> {data.connectorFulfillmentDetails.storeName}</p>
            <p><strong>Address:</strong> {data.connectorFulfillmentDetails.location.addressLine1}, {data.connectorFulfillmentDetails.location.city}, {data.connectorFulfillmentDetails.location.state}, {data.connectorFulfillmentDetails.location.pincode}</p>
            <p><strong>Amenities:</strong> {data.connectorFulfillmentDetails.amenities.amenities.join(', ')}</p>
          </div>

          <div className="mb-4">
            <h3 className="font-semibold">Connector Details</h3>
            <p><strong>Connector Title:</strong> {data.connectorFulfillmentDetails.connectorTitle}</p>
            <p><strong>Connector Type:</strong> {data.connectorFulfillmentDetails.connectorType}</p>
            <p><strong>Current Type:</strong> {data.connectorFulfillmentDetails.currentType}</p>
            <p><strong>Power Rating:</strong> {data.connectorFulfillmentDetails.powerRatingKW} kW</p>
            <p><strong>Speed:</strong> {data.connectorFulfillmentDetails.speed}</p>
            <p><strong>Price:</strong> {data.connectorFulfillmentDetails.price.rate} {data.connectorFulfillmentDetails.price.unit}</p>
          </div>

          <div className="mb-4">
            <h3 className="font-semibold">Availability Details</h3>
            <p><strong>Health Status:</strong> {data.connectorFulfillmentDetails.availabilityDetails.healthy ? 'Healthy' : 'Unhealthy'}</p>
            <p><strong>Booked:</strong> {data.connectorFulfillmentDetails.availabilityDetails.booked ? 'Yes' : 'No'}</p>
            <p><strong>Next Slot Available At:</strong> {data.connectorFulfillmentDetails.availabilityDetails.nextSlotAvailableAt.hourOfDay}:{data.connectorFulfillmentDetails.availabilityDetails.nextSlotAvailableAt.minuteOfHour} {data.connectorFulfillmentDetails.availabilityDetails.nextSlotAvailableAt.ampm}</p>
            <p><strong>Slot Details:</strong> {formatSlotDetails(data.connectorFulfillmentDetails.availabilityDetails.slotDetails)}</p>
          </div>

          <div className="mb-4">
            <h3 className="font-semibold">Fulfillment Details</h3>
            <p>{formatFulfillment(data.connectorFulfillmentDetails.fulfillmentContext)}</p>
          </div>

        </div>
      )}
    </div>
  );
};

export default QRResolve;
