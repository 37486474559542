import React, { useState } from "react";
import { fetchChargerList, updateStore } from "../api";
import {
  GoogleMap,
  Marker,
  useLoadScript,
} from "@react-google-maps/api";
import StoreDetailsEditor from "../components/StoreDetailsEditor";
import InfoWindowMap from "./InfoWindowMap";

const stateOptions = [
  { value: "CLOSED", label: "CLOSED" },
  { value: "DISABLED", label: "DISABLED" },
  { value: "PENDING_REVIEW", label: "PENDING_REVIEW" },
  { value: "ENABLED", label: "ENABLED" },
  { value: "NOT_ACTIVATED", label: "NOT_ACTIVATED" },
  { value: "BLACKLISTED", label: "BLACKLISTED" },
];

const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg mx-4 w-full max-w-md sm:max-w-lg md:max-w-xl max-h-[90vh] overflow-y-auto">
        <div className="p-4 sm:p-6">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-xl sm:text-2xl font-bold">Store Details</h2>
            <button
              onClick={onClose}
              className="text-gray-500 hover:text-gray-700 text-xl sm:text-2xl font-bold"
            >
              ×
            </button>
          </div>
          {children}
        </div>
      </div>
    </div>
  );
};

const ChargingStationsMap = () => {
  const [location, setLocation] = useState({
    latitude: "",
    longitude: "",
    radiusInMeters: 50000,
  });
  const [states, setStates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageSize, setPageSize] = useState(20);
  const [chargingLocations, setChargingLocations] = useState([]);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [selectedStore, setSelectedStore] = useState(null);
  const [storeContext, setStoreContext] = useState([]);
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);
  const [, setErrorMessage] = useState("");

  const handleMarkerClick = (marker) => {
    setSelectedMarker(marker);
  };

  const handleStatesChange = (e) => {
    setStates([...e.target.selectedOptions].map((option) => option.value));
  };

  const handleLocationChange = (e) => {
    setLocation((prevLocation) => ({
      ...prevLocation,
      [e?.target?.name]: e?.target?.value,
    }));
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      fetchData();
    } catch (err) {
      console.error("Error fetching data:", err);
    }
  };

  const handleStoreUpdate = async (updatedStoreData) => {
    try {
      await updateStore(updatedStoreData?.store?.storeId, updatedStoreData);
      alert("Store updated successfully!");
    } catch (error) {
      console.error("Error updating store:", error);
      alert("An error occurred while updating the store.");
    }
  };

  const handleStoreDetails = (storeId) => {
    const store = storeContext.find(
      (context) => context.store.storeId === storeId
    );
    if (store) {
      setSelectedStore(store);
      setIsDetailsOpen(true);
    } else {
      setErrorMessage("Store not found.");
    }
  };

  const fetchData = async () => {
    try {
      const response = await fetchChargerList(location, states, pageSize);
      const data = response;
      setStoreContext(data.storeContexts);
      const extractedLocations = data.storeContexts.map((storeContext) => {
        const store = storeContext.store;
        return {
          id: store.id,
          name: store.name,
          storeId: store.storeId,
          description: store.description,
          city: store.city,
          indianState: store.indianState,
          meta: store.meta,
          locationDescription: store?.meta?.locationDescription,
          latitude: store.latitude,
          longitude: store.longitude,
          image: storeContext?.primaryStoreImage?.s3Url || "",
        };
      });
      setChargingLocations(extractedLocations);
    } catch (err) {
      console.error("Error fetching data:", err);
      setErrorMessage("Failed to load charging locations. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  });

  if (!isLoaded) {
    return <p>Loading map...</p>;
  }

  return (
    <div>
      {/* Map and Form Container */}
      <div className="p-4">
        <h1 className="text-2xl font-bold mb-4">Charging Stations Map</h1>

        {/* Form for filters */}
        <form onSubmit={handleFormSubmit} className="mb-4">
          <div className="mb-4">
            <label htmlFor="latitude" className="block font-medium mb-2">
              Latitude
            </label>
            <input
              type="text"
              id="latitude"
              name="latitude"
              className="border rounded px-3 py-2 w-full"
              placeholder="Enter latitude"
              value={location.latitude}
              onChange={handleLocationChange}
            />
          </div>
          <div className="mb-4">
            <label htmlFor="longitude" className="block font-medium mb-2">
              Longitude
            </label>
            <input
              type="text"
              id="longitude"
              name="longitude"
              className="border rounded px-3 py-2 w-full"
              placeholder="Enter longitude"
              value={location.longitude}
              onChange={handleLocationChange}
            />
          </div>
          <div className="mb-4">
            <label htmlFor="states" className="block font-medium mb-2">
              States
            </label>
            <select
              id="states"
              name="states"
              multiple
              className="border rounded px-3 py-2 w-full"
              onChange={handleStatesChange}
              value={states}
            >
              {stateOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-4">
            <label htmlFor="pageSize" className="block font-medium mb-2">
              Page Size
            </label>
            <input
              type="number"
              id="pageSize"
              name="pageSize"
              className="border rounded px-3 py-2 w-full"
              placeholder="Enter page size"
              value={pageSize}
              onChange={(e) => setPageSize(Number(e.target.value))}
            />
          </div>
          <button
            type="submit"
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            disabled={loading}
          >
            {loading ? "Searching..." : "Search"}
          </button>
        </form>
      </div>

      {/* Map Section */}
      <div className="h-[600px] rounded-lg overflow-hidden">
        <GoogleMap
          mapContainerClassName="w-full h-full"
          center={
            chargingLocations.length > 0
              ? {
                  lat: chargingLocations[0].latitude,
                  lng: chargingLocations[0].longitude,
                }
              : { lat: 13.037943, lng: 77.55778 }
          }
          zoom={12}
        >
          {chargingLocations.map((location, index) => (
            <Marker
              key={index}
              position={{
                lat: location.latitude,
                lng: location.longitude,
              }}
              onClick={() => handleMarkerClick(location)}
            />
          ))}

          {selectedMarker && (
            <InfoWindowMap
              selectedMarker={selectedMarker}
              onClose={() => setSelectedMarker(null)}
              onStoreClick={handleStoreDetails}
            />
          )}
        </GoogleMap>
      </div>
      <Modal isOpen={isDetailsOpen} onClose={() => setIsDetailsOpen(false)}>
        {selectedStore ? (
          <StoreDetailsEditor data={selectedStore} onSave={handleStoreUpdate} />
        ) : (
          <div>Loading store details...</div>
        )}
      </Modal>
    </div>
  );
};

export default ChargingStationsMap;
