import { useState } from 'react';
import { getBookingDetails } from '../api';

const BookingDetails = () => {
  const [bookingId, setBookingId] = useState('');
  const [bookingDetails, setBookingDetails] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchBookingDetails = async () => {
    if (!bookingId) {
      setError('Please enter a booking ID');
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const data = await getBookingDetails(bookingId);
      setBookingDetails(data);
    } catch (err) {
      
      setError(err.response ? err.response.data.message : 'Error occurred');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="p-4">
      <div className="mb-4">
        <label className="block text-gray-700 font-bold mb-2">Enter Booking ID:</label>
        <input
          type="text"
          value={bookingId}
          onChange={(e) => setBookingId(e.target.value)}
          className="border border-gray-300 p-2 rounded w-full"
          placeholder="Enter Booking ID"
        />
      </div>

      <button
        onClick={fetchBookingDetails}
        className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 focus:outline-none"
      >
        Get Booking Details
      </button>

      {loading && <div className="mt-4 text-blue-600">Loading booking details...</div>}
      {error && <div className="mt-4 text-red-500">{error}</div>}

      {bookingDetails && (
        <div className="mt-4 p-6 bg-white rounded shadow-md">
          <h1 className="text-2xl font-bold mb-4">Booking Details</h1>
          <p><strong>Booking ID:</strong> {bookingDetails.bookingId}</p>
          <p><strong>Status:</strong> {bookingDetails.status}</p>
          <p><strong>Vehicle ID:</strong> {bookingDetails.vehicleId}</p>
          <p><strong>User ID:</strong> {bookingDetails.userId}</p>
          <p><strong>Units:</strong> {bookingDetails.units} kWh</p>
          <p><strong>Disbursed Units:</strong> {bookingDetails.disbursedUnits} kWh</p>
          <p><strong>Estimated Start Delay (Minutes):</strong> {bookingDetails.estimatedStartDelayMinutes || 'N/A'}</p>
          <p><strong>Estimated Charging Time:</strong> {bookingDetails.estimatedChargingTime || 'N/A'}</p>
          <p><strong>Current Queue Size:</strong> {bookingDetails.currentQueueSize}</p>
          <p><strong>Token Number:</strong> {bookingDetails.tokenNumber}</p>
          <p><strong>OTP:</strong> {bookingDetails.otp || 'N/A'}</p>

          <h2 className="text-xl font-bold mt-4 mb-2">Connector Meta:</h2>
          <p><strong>Connector ID:</strong> {bookingDetails.connectorMeta?.connectorId || 'N/A'}</p>
          <p><strong>Operator ID:</strong> {bookingDetails.connectorMeta?.operatorId || 'N/A'}</p>
          <p><strong>Store ID:</strong> {bookingDetails.connectorMeta?.storeId || 'N/A'}</p>
          <p><strong>Charging Station ID:</strong> {bookingDetails.connectorMeta?.chargingStationId || 'N/A'}</p>
          <p><strong>EVSE ID:</strong> {bookingDetails.connectorMeta?.evseId || 'N/A'}</p>
          <p><strong>Type:</strong> {bookingDetails.connectorMeta?.type || 'N/A'}</p>
          <p><strong>Power Rating:</strong> {bookingDetails.connectorMeta?.powerRatingKWH || 'N/A'} kWh</p>
          <p><strong>Price Rate:</strong> {bookingDetails.connectorMeta?.price?.rate || 'N/A'}</p>
          <p><strong>Price Unit:</strong> {bookingDetails.connectorMeta?.price?.unit || 'N/A'}</p>

          <h2 className="text-xl font-bold mt-4 mb-2">Timestamps:</h2>
          <p><strong>Created At:</strong> {bookingDetails.createdAt ? new Date(bookingDetails.createdAt).toLocaleString() : 'N/A'}</p>
          <p><strong>Updated At:</strong> {bookingDetails.updatedAt ? new Date(bookingDetails.updatedAt).toLocaleString() : 'N/A'}</p>
        </div>
      )}
    </div>
  );
};

export default BookingDetails;
