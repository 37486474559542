const endpoints = {

  verifyMigration: (requestId) =>
    `/store/pg/migration/${requestId}/verify`,

  cleanupMigration: (requestId) =>
    `/store/pg/migration/${requestId}/cleanup`,

  getAllOperators: '/internaluser/operator/list',

  getOperatorDetails: (operatorId) =>
    `/internaluser/operator/${operatorId}/details`,

  fetchLocationSuggestions1: (query) =>
    `/internaluser/google/place/autocomplete?prompt=${query}`,

  fetchHealthCheck: '/health',

};

export default endpoints;