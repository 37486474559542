import React, { useEffect, useState } from "react";
import { getAllOperators, getOperatorDetails } from "../api";
// import { CodeBlock } from 'react-code-blocks';
import { JsonViewer } from "@textea/json-viewer";
import { FaTimes } from "react-icons/fa";
import {
  INITIAL_EDIT_FORM,
  INITIAL_SEARCH_VALUE,
  SEARCH_TYPES,
} from "./Constants";

const Operators = () => {
  const [operators, setOperators] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedOperator, setSelectedOperator] = useState(null);
  const [isEditing, setIsEditing] = useState(INITIAL_EDIT_FORM);
  const [editForm, setEditForm] = useState({});
  const [searchType, setSearchType] = useState("#");
  const [searchValue, setSearchValue] = useState(INITIAL_SEARCH_VALUE);
  const { ALL, OPERATOR_ID, NETWORK } = SEARCH_TYPES;

  useEffect(() => {
    if (searchType === "all") fetchAllOperators();
  }, [searchType]);

  const fetchAllOperators = async () => {
    try {
      const data = await getAllOperators();
      setOperators(data);
    } catch (error) {
      console.error("Failed to fetch operators:", error);
      setError("Failed to load operators");
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    if (searchType === "all") {
      fetchAllOperators();
    } else if (searchType === "operatorId") {
      try {
        const details = await getOperatorDetails(searchValue);
        setOperators([details]);
      } catch (error) {
        console.error("Failed to fetch operator by ID:", error);
        setError("Operator not found");
      }
    } else if (searchType === "network") {
      try {
        const data = await getAllOperators();
        const filteredOperators = data.filter(
          (operator) =>
            operator.network.toLowerCase() === searchValue.toLowerCase()
        );
        setOperators(filteredOperators);
      } catch (error) {
        console.error("Failed to fetch operators by network:", error);
        setError("Failed to load operators");
      }
    }
    setLoading(false);
  };

  const handleOperatorClick = async (operatorId) => {
    try {
      const details = await getOperatorDetails(operatorId);
      setSelectedOperator(details);
      setEditForm(details);
      setIsEditing(false);
    } catch (error) {
      console.error("Failed to fetch operator details:", error);
    }
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveChanges = async () => {
    try {
      setSelectedOperator(editForm);
      setIsEditing(false);
    } catch (error) {
      console.error("Failed to update operator details:", error);
    }
  };

  const closeModal = () => {
    setSelectedOperator(null);
  };

  useEffect(() => {
    setOperators([]);
    setSearchValue("");
  }, [searchType]);

  if (loading) return <p className="text-center">Loading...</p>;
  if (error) return <p className="text-red-500 text-center">{error}</p>;

  return (
    <div className="min-h-screen flex flex-col items-center p-4">
      <div className="bg-white shadow-lg rounded-lg p-6 w-full max-w-7xl">
        <h1 className="text-2xl font-semibold text-gray-800 mb-6">
          Operator List
        </h1>

        {/* Search Section */}
        <form
          onSubmit={handleSearch}
          className="flex flex-col gap-4 md:flex-row md:items-center mb-6"
        >
          <select
            value={searchType}
            onChange={(e) => setSearchType(e.target.value)}
            className="border rounded px-4 py-2 w-full md:w-auto"
          >
            <option value="#">Select Options</option>
            <option value={ALL}>Get All Operators</option>
            <option value={OPERATOR_ID}>
              Search by Operator ID
            </option>
            <option value={NETWORK}>Search by Network</option>
          </select>

          {searchType !== "all" && searchType !== "#" && (
            <input
              type="text"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              placeholder={`Enter ${
                searchType === "operatorId" ? "Operator ID" : "Network"
              }`}
              className="border rounded px-4 py-2 w-full md:w-auto"
            />
          )}

          <button
            type="submit"
            className="bg-blue-500 text-white px-4 py-2 rounded w-full md:w-auto"
          >
            Search
          </button>
        </form>

        {operators.length > 0 && (
          <div className="w-full">
            <div className="hidden sm:block">
              <table className="w-full border border-gray-300 text-sm sm:text-base">
                <thead>
                  <tr className="bg-gray-100 text-gray-700">
                    <th className="py-2 px-4 border-b text-left">OperatorId</th>
                    <th className="py-2 px-4 border-b text-left">Name</th>
                    <th className="py-2 px-4 border-b text-left">Network</th>
                    <th className="py-2 px-4 border-b text-right">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {Array.isArray(operators) && operators.length > 0 ? (
                    operators.map((operator) => (
                      <tr key={operator.id} className="hover:bg-gray-50">
                        <td className="py-2 px-4 border-b">
                          {operator?.operatorId}
                        </td>
                        <td className="py-2 px-4 border-b">{operator?.name}</td>
                        <td className="py-2 px-4 border-b">
                          {operator?.network}
                        </td>
                        <td className="py-2 px-4 border-b text-right">
                          <button
                            onClick={() =>
                              handleOperatorClick(operator?.operatorId)
                            }
                            className="bg-blue-500 text-white py-1 px-3 rounded hover:bg-blue-600"
                          >
                            View Details
                          </button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="4" className="py-2 px-4 text-center">
                        No operators available.
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>

            <div className="sm:hidden">
              {Array.isArray(operators) &&
                operators.map((operator) => (
                  <div
                    key={operator.id}
                    className="border border-gray-300 rounded-lg p-4 mb-4 bg-white shadow-sm"
                  >
                    <div className="mb-2">
                      <strong>OperatorId:</strong> {operator.operatorId}
                    </div>
                    <div className="mb-2">
                      <strong>Name:</strong> {operator.name}
                    </div>
                    <div className="mb-2">
                      <strong>Network:</strong> {operator.network}
                    </div>
                    <div className="flex justify-center items-center">
                      <button
                        onClick={() => handleOperatorClick(operator.operatorId)}
                        className="bg-blue-500 text-white py-1 px-3 rounded hover:bg-blue-600"
                      >
                        View Details
                      </button>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        )}

        {selectedOperator && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
            <div className="relative bg-white rounded-lg p-6 w-full max-w-2xl h-auto max-h-[80vh] overflow-y-auto">
              <button
                onClick={closeModal}
                className="absolute top-2 right-2 text-red-500 hover:text-red-600"
              >
                <FaTimes size={20} />
              </button>

              <h2 className="text-xl font-bold mb-4">Operator Details</h2>
              {isEditing ? (
                <div>
                  <JsonViewer
                    editable={true}
                    displayDataTypes={false}
                    value={editForm}
                  />
                  <div className="flex justify-start gap-2 mt-4">
                    <button
                      onClick={handleSaveChanges}
                      className="bg-green-500 text-white py-1 px-4 rounded mr-2 hover:bg-green-600"
                    >
                      Save Changes
                    </button>
                    <button
                      onClick={() => setIsEditing(false)}
                      className="bg-red-500 text-white py-1 px-4 rounded hover:bg-red-600"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              ) : (
                <div className="text-ellipsis">
                  <JsonViewer
                    editable={false}
                    displayDataTypes={false}
                    rootName={null}
                    enableClipboard={false}
                    defaultExpandDepth={1}
                    quotesOnKeys={false}
                    indentWidth={4}
                    value={selectedOperator}
                  />
                  <div className="flex justify-start gap-2 mt-4">
                    <button
                      onClick={handleEditClick}
                      className="bg-blue-500 text-white py-1 px-4 rounded hover:bg-blue-600"
                    >
                      Edit
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Operators;
