import adminApiInstance from './adminInstance';
import apiInstance from './instance';
import endpoints from './endpoints/endpoints'

// Function to add a new vehicle model
export const addVehicleModel = async (payload) => {
  try {
    const response = await apiInstance({
      endpoint: '/model',
      method: 'POST',
      body: payload,
    });
    return response;
  } catch (error) {
    console.error('Error adding vehicle model:', error);
    throw error;
  }
};

// Function to fetch booking details by ID
export const getBookingDetails = async (bookingId) => {
  try {
    const response = await apiInstance({
      endpoint: `/internaluser/booking/${bookingId}/details`,
      method: 'GET',
    });
    return response;
  } catch (error) {
    console.error('Error fetching booking details:', error);
    throw error;
  }
};

// Function to create a business user
export const createBusinessUser = async (formData) => {
  try {
    const response = await apiInstance({
      endpoint: '/internaluser/business-user-creation',
      method: 'POST',
      body: formData,
    });
    return response;
  } catch (error) {
    console.error('Error creating business user:', error);
    throw error;
  }
};

// Function to fetch models
export const fetchModels = async () => {
  try {
    const response = await apiInstance({
      endpoint: '/models/list',
      method: 'POST',
      body: {
        wheelsizeRestriction: [],
        vendorRestriction: [],
      },
    });
    return response.models;
  } catch (error) {
    throw new Error('Error fetching models: ' + error.message);
  }
};

// Function to resolve QR code
export const resolveQR = async (chargerId) => {
  try {
    const response = await apiInstance({
      endpoint: '/internaluser/qr/resolve',
      method: 'POST',
      body: {
        qrText: `chargepe://charge?cid=${chargerId}`,
      },
    });
    return response;
  } catch (error) {
    throw new Error('Error resolving QR code: ' + error.message);
  }
};

// New function to fetch migration request details by request ID
export const getMigrationDetails = async (requestId) => {
  try {
    const response = await apiInstance({
      endpoint: `/store/pg/migration/${requestId}/details`,
      method: 'GET',
    });
    return response;
  } catch (error) {
    console.error('Error fetching migration details:', error);
    throw error;
  }
};

// For verify migration request
export const verifyMigration = async (requestId) => {
  try {
    const response = await apiInstance({
      endpoint: endpoints.verifyMigration(requestId),
      method: 'PUT',
    });
    return response;
  } catch (error) {
    console.error('Error verifying migration:', error);
    return false;
  }
};

// For cleanup migration request
export const cleanupMigration = async (requestId) => {
  try {
    const response = await apiInstance({
      endpoint: endpoints.cleanupMigration(requestId),
      method: 'PUT',
    });
    return response;
  } catch (error) {
    console.error('Error cleaning up migration:', error);
    return false;
  }
};

// For get all operator
export const getAllOperators = async () => {
  try{
    const response = await apiInstance({
      endpoint: endpoints.getAllOperators,
    method: 'GET',
    });
    return response;
  } catch (error) {
  console.error('Error fetching migration details:', error);
    throw error;
  }
};

// For operator details
export const getOperatorDetails = async (operatorId) => {
  try {
    const response = await apiInstance({
      endpoint: endpoints.getOperatorDetails(operatorId),
      method: 'GET',
    });
    return response;
  } catch (error) {
    console.error('Error verifying migration:', error);
    return false;
  }
};


export const fetchStoreDetails = async (storeId) => {
  try {
    const response = await apiInstance({
      endpoint: `/internaluser/store/${storeId}/details`,
      method: 'GET',
    });
    return response;
  } catch (error) {
    console.error('Error fetching store details:', error);
    throw error;
  }
};

// Functions from InternalStoresSearch component
export const fetchStores = async (location, states, page, pageSize) => {
  try {
    const response = await apiInstance({
      endpoint: '/internaluser/stores/list',
      method: 'POST',
      body: {
        latLng: {
          latitude: location.latitude,
          longitude: location.longitude,
        },
        radiusMeters: 50000,
        states,
        pageRequest: {
          pageNumber: page,
          pageSize,
        },
      },
    });
    return response.storeContexts;
  } catch (error) {
    console.error('Error fetching stores:', error);
    throw error;
  }
};

export const updateStore = async (storeId, updatedStoreData) => {
  try {
    const response = await apiInstance({
      endpoint: `/internaluser/store/${storeId}`,
      method: 'PUT',
      body: updatedStoreData,
    });
    return response;
  } catch (error) {
    console.error('Error updating store:', error);
    throw error;
  }
};

// Functions from StoreDetailsEditor component
export const approveImage = async (storeId, imageId) => {
  try {
    const response = await apiInstance({
      endpoint: `/store/${storeId}/${imageId}/approve`,
      method: 'PUT',
    });
    return response;
  } catch (error) {
    console.error('Error approving image:', error);
    throw error;
  }
};

export const rejectImage = async (storeId, imageId) => {
  try {
    const response = await apiInstance({
      endpoint: `/store/${storeId}/${imageId}/reject`,
      method: 'PUT',
    });
    return response;
  } catch (error) {
    console.error('Error rejecting image:', error);
    throw error;
  }
};

export const updateStoreData = async (storeId, updatedStoreData) => {
  try {
    const response = await apiInstance({
      endpoint: `/internaluser/store/${storeId}`,
      method: 'PUT',
      body: updatedStoreData,
    });
    return response;
  } catch (error) {
    console.error('Error updating store data:', error);
    throw error;
  }
};

// Location suggestions
export const fetchLocationSuggestions = async (query, setSuggestions) => {
  try {
    const response = await apiInstance({
      endpoint: endpoints.fetchLocationSuggestions1(query),
        method: 'GET',
      }
    )    
    setSuggestions(response.predictions);
    return response;
  } catch (error) {
    console.error("Error fetching data:", error);
    setSuggestions([]);
  }
};

// healthCheck api
export const fetchHealthCheck = async () => {
  try {
    const response = await adminApiInstance({
      endpoint: endpoints.fetchHealthCheck,
      method: 'GET',
    });
    return response;
  } catch (error) {
  }
};

// Function to create a New Store
export const createNewStore = async (formData) => {
  try {
    const response = await apiInstance({
      endpoint: '/internaluser/store/init',
      method: 'POST',
      body: formData,
    });
    return response;
  } catch (error) {
    console.error('Error creating New Store:', error);
    throw error;
  }
};

//Function To Fetch Enums for Store Creation
export const fetchEnums = async () => {
  try {
    const response = await apiInstance({
        endpoint:'/internaluser/store/write-request-creation-data',
        method:'GET'
    });
    return response;
  } catch (error) {
      console.error('Error fetching enums:', error);
    throw error;
  }
};

//Function To Fetch Connector Type
export const fetchConnectorTypes = async () => {
  try {
    const response = await apiInstance({
      endpoint: "/connector-types",
      method: 'GET',
    });
    return response;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

//Function to Add Charging Stations
export const AddChargingStation = async (formData) => {
  try {
    const response = await apiInstance({
      endpoint: "/internaluser/charging-station",
      method: 'POST',
      body: formData,
    });
    if (response) {
      return response;
    }
  } catch (error) {
    console.error("Error Adding Charging Station:", error.message);
  }
};


// Function to get a presigned URL for image upload
export const getPresignedUrl = async ( interactionType,interactionId,storeId ) => {
  try {
    const response = await apiInstance({
      endpoint: "/internaluser/store/add-image/pre-auth",
      method: "POST",
      body: {
        interactionType: interactionType,
        interactionId: interactionId,
        storeId: storeId,
        imageFormat: "JPG",
      },
     
    });
    return response;
  } catch (error) {
    console.error("Error in getPresignedUrl:", error.message);
    throw new Error("Failed to get upload URL");
  }
};

// Function to verify image upload
export const verifyUpload = async (storeId, imageId) => {
  try {
    const response = await apiInstance({
      endpoint: `/store/${storeId}/${imageId}/verify-upload`,
      method: 'PUT',
    });
    return response;
  } catch (error) {
    console.error('Error in verifyUpload:', error.message);
    throw new Error('Failed to verify upload');
  }
};

export const fetchChargerList = async (location, states, page) => {
  try {
    const response = await apiInstance({
      endpoint: "/internaluser/stores/list/complete",
      method: "POST",
      body: {
        latLng: {
          latitude: location.latitude,
          longitude: location.longitude,
        },
        radiusMeters: 50000,
        states,
        pretty: true,
        size: page,
      },
    });
    return response;
  } catch (error) {
    console.error("Error fetching Charging Store:", error);
    throw error;
  }
};
