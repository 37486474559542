import React, { useEffect, useState } from 'react';
import { fetchHealthCheck } from '../api';
import { getAuth } from '../utils/common';

function HealthCheck() {
  const [X, setX] = useState('');
  const [results, setResults] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [userType, ] = useState(null);

  useEffect(() => {
    const auth = getAuth();
    const user = JSON.parse(auth);
    if(user.roles[0]!=="INTERNAL_ADMIN"){
    window.location.href="/*"
      return;
    }
  }, [userType])
  
  const handleHealthCheck = async () => {
    if (!X || X < 1 || X > 10000) {
      alert('Please enter a valid number (1 - 10000)');
      return;
    }

    setIsLoading(true);
    const latencies = [];
    const statusCounts = {};

    try {
      for (let i = 0; i < X; i++) {
        const startTime = Date.now();
        const response = await fetchHealthCheck();
        const latency = Date.now() - startTime;
        latencies.push(latency);
        const status = response.status;
        statusCounts[status] = (statusCounts[status] || 0) + 1;
      }

      const sortedLatencies = latencies.sort((a, b) => a - b);
      const getPercentile = (p) => 
        sortedLatencies[Math.floor(p * sortedLatencies.length)] || 0;
      
      const percentiles = {
        p50: getPercentile(0.5),
        p75: getPercentile(0.75),
        p90: getPercentile(0.9),
        p99: getPercentile(0.99),
      };
      
      setResults({ percentiles, statusCounts });
    } catch (error) {
      alert('Error during health check. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="max-w-3xl mx-auto p-6 bg-gray-100 shadow-md rounded-lg">
      <h2 className="text-2xl font-bold text-center text-gray-700 mb-4">
        Health Check Dashboard
      </h2>
      <div className="flex flex-col items-center space-y-4">
      <input
        type="number"
        value={X || ''}
        onChange={(e) => {
          const value = Number(e.target.value);
          if (value >= 0 && value <= 10000) {
            setX(value); 
          } else if (e.target.value === '') {
            setX(''); 
          }
        }}
        placeholder="Enter X (1-10000)"
        className="w-full max-w-sm px-4 py-2 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
        min="0"
        max="10000"
        step="1"
      />

        <button
          onClick={handleHealthCheck}
          disabled={isLoading}
          className={`px-6 py-2 text-white rounded-lg shadow ${
            isLoading
              ? 'bg-gray-500 cursor-not-allowed'
              : 'bg-blue-500 hover:bg-blue-600'
          }`}
        >
          {isLoading ? 'Running...' : 'Run Health Check'}
        </button>
      </div>

      {results && (
        <div className="mt-6 bg-white p-4 rounded-lg shadow">
          <h3 className="text-lg font-semibold text-gray-700 mb-3">
            Health Check Results
          </h3>

          <div className="mb-4">
            <h4 className="font-medium text-gray-600">Latency Percentiles</h4>
            <p className="text-gray-600">p50: {results.percentiles.p50} ms</p>
            <p className="text-gray-600">p75: {results.percentiles.p75} ms</p>
            <p className="text-gray-600">p90: {results.percentiles.p90} ms</p>
            <p className="text-gray-600">p99: {results.percentiles.p99} ms</p>
          </div>

          <div>
            <h4 className="font-medium text-gray-600">HTTP Status Code Breakdown</h4>
            {Object.entries(results.statusCounts).map(([status, count]) => (
              <p key={status} className="text-gray-600">
                <strong>{status}:</strong> {count}
              </p>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default HealthCheck;
