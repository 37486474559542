import React from "react";
import { Route, Routes } from "react-router-dom";
import Sidebar from "../components/Sidebar";
import CreateBusiness from "./CreateBusiness";
import QRResolve from "./QRResolve";
import BookingDetails from "./BookingDetails";
import AddVehicle from "./AddVehicle";
import ModelsList from "./ModelList";
import Migration from "./Migration";
// import ImageUploader from '../components/AddImage';
import StoreDetails from "./StoreDetails";
import Operator from "./Operator";
import InternalStoresSearch from "./StoreSearch";
import AddStore from "./AddStore";
import PlacesAutocomplete from "../components/PlacesAutocomplete";
import AddOutlet from "./AddOutlet";
import ImageUpload from "./ImageUpload";
import { AppProvider } from "../Context";
import HealthCheck from "./HealthCheck";
import ChargingStationsMap from "./ChargingStationsMap";
import StoreList from "../components/StoreList";
import { ROUTES } from "../routes";

const HomePage = () => {
  return (
    <div className="flex">
      <Sidebar />

      <div className="flex-1 p-6 md:ml-64">
        <AppProvider>
        <Routes>
            <Route path={ROUTES.CREATE_BUSINESS} element={<CreateBusiness />} />
            <Route path={ROUTES.QR_RESOLVE} element={<QRResolve />} />
            <Route path={ROUTES.BOOKING_DETAILS} element={<BookingDetails />} />
            <Route path={ROUTES.ADD_VEHICLE} element={<AddVehicle />} />
            <Route path={ROUTES.MODEL_LIST} element={<ModelsList />} />
            <Route path={ROUTES.MIGRATION} element={<Migration />} />
            <Route path={ROUTES.STORE_DETAILS} element={<StoreDetails />} />
            <Route path={ROUTES.STORE_LIST} element={<StoreList />} />
            <Route path={ROUTES.STORE_SEARCH} element={<InternalStoresSearch />} />
            <Route path={ROUTES.OPERATOR} element={<Operator />} />
            <Route path={ROUTES.ADD_STORE} element={<AddStore />} />
            <Route path={ROUTES.PLACES} element={<PlacesAutocomplete />} />
            <Route path={ROUTES.OUTLET} element={<AddOutlet />} />
            <Route path={ROUTES.IMAGE} element={<ImageUpload />} />
            <Route path={ROUTES.HEALTH_CHECK} element={<HealthCheck />} />
            <Route path={ROUTES.CHARGING_STATION} element={<ChargingStationsMap/>} />
          </Routes>
        </AppProvider>
      </div>
    </div>
  );
};

export default HomePage;
