import React from "react";
import { InfoWindow } from "@react-google-maps/api"; // Import as needed

const InfoWindowMap = ({ selectedMarker, onClose, onStoreClick }) => {
  if (!selectedMarker) return null;

  return (
    <InfoWindow
      position={{
        lat: selectedMarker.latitude,
        lng: selectedMarker.longitude,
      }}
      onCloseClick={onClose}
    >
      <div
        className="w-64 text-sm cursor-pointer"
        onClick={() => onStoreClick(selectedMarker.storeId)}
      >
        <h3 className="font-bold mb-2">{selectedMarker.name}</h3>
        {selectedMarker.image && (
          <img
            src={selectedMarker.image}
            alt={selectedMarker.name}
            className="w-full h-32 object-cover rounded-md mb-2"
          />
        )}
        <p className="mb-1">
          <strong>Store ID:</strong> {selectedMarker.storeId || "Not Available"}
        </p>
        <p className="mb-1">
          <strong>City:</strong> {selectedMarker.city || "Unknown"}
        </p>
        <p>
          <strong>State:</strong> {selectedMarker.indianState || "Unknown"}
        </p>
        <p>
          <strong>Timing:</strong>{" "}
          {selectedMarker.meta?.timings?.available247
            ? "Open 24/7"
            : selectedMarker.meta?.timings?.openHours || "Unknown"}
        </p>
      </div>
    </InfoWindow>
  );
};

export default InfoWindowMap;
