import React, { useState, useEffect } from 'react';
import { fetchEnums, getAllOperators, fetchLocationSuggestions,updateStore } from '../api';

const StoreList = (storeDetails) => {
  const [storeResponse, setStoreResponse] = useState(null);
  const [operatorDetails, setOperatorDetails] = useState([]);
  const [isEditable, setIsEditable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const[responsePlaceId,setResponsePlaceId]=useState("")
  const [allOperators, setAllOperators] = useState([]);
  const [allNetworks, setAllNetworks] = useState([]);
  const [placeResults, setPlaceResults] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [dropdownData, setDropdownData] = useState({
    locationTypes: [],
    locationTags: [],
    sizes: [],
    restrictionLevels: [],
    parkingLevels: [],
    amenitiesOptions: [], 
  });
  const [storeId, setStoreId] = useState(null);
  if(storeResponse || operatorDetails ||loading){
  }

  const [formValues, setFormValues] = useState({
    operator: '',
    name: '',
    description: '',
    city: '',
    indianState: '',
    // state: '',
    locationType: '',
    parking: '',
    restrictionLevel: '',
    size: '',
    available247: false,
    phoneNumber: '',
    amenities: [], 
    locationDescription: '',
    latitude:"",
    longitude:"",
    locationtag:"",
   
  });
 
  useEffect(() => {
    if (isEditable) {
      const loadEnums = async () => {
        try {
          const data = await fetchEnums();
          console.log('Fetched Enums:', data);
          setDropdownData({
            locationTypes: data?.locationTypes || [],
            locationTags: data?.locationTags || [],
            sizes: data?.sizes || [],
            restrictionLevels: data?.restrictionLevels || [],
            parkingLevels: data?.parkingLevels || [],
            amenitiesOptions: data?.amenityTypes || [], 
          });
        } catch (err) {
          console.error('Error fetching dropdown options:', err);
          setError('Failed to fetch dropdown options.');
        }
      };
      setTimeout(() => {
        loadEnums();
      }, 1000); 
    }
  }, [isEditable]);

  useEffect(() => {
    const fetchPlaces = async () => {
      if (!searchQuery) {
        console.log("Please enter a location prompt.");
        return;
      }
    
      try {
        const response = await fetchLocationSuggestions(searchQuery, setPlaceResults);
        console.log("Place Prediction",response?.predictions); 
        // console.log("Place result",placeResults)
      } catch (error) {
        console.error("Error fetching places:", error);
      }
    };
    
  fetchPlaces(); 
  }, [searchQuery]);
    
  useEffect(() => {
    const fetchDetails = async () => {
      setLoading(true);
      try {
      const response = storeDetails?.data ; 
      setStoreId(response?.storeId);
      console.log("Storeidddd",response?.storeId)
      console.log("DEATILS IN STORELIST",response)
       const {
        gplaceId='',
        name = '',
        description = '',
        city = '',
        indianState = '',
        // state = '',
        locationType = '',
        parkingLevel = '',
        restrictionLevel = '',
        size = '',
        meta = {},
        latitude='',
        longitude=''
      } = response;

      const available247 = meta?.timings?.available247 || false;
      const amenities = meta?.amenities?.amenityTypes || []; 
      const phoneNumber = meta?.contactInfo?.primarySupportNumber?.phoneNumber || '';
      const locationDescription = meta?.locationDescription?.addressLine1 || '';
      const locationtag =meta?.locationTags ||"";
      setStoreResponse(response);
      setResponsePlaceId(gplaceId);
      const operators = await getAllOperators();
      const matchedOperator = operators.find((operator) => operator.operatorId === response.operatorId);
      setOperatorDetails(matchedOperator || null);
      const networks = operators.map((op) => op.network);
      setAllNetworks(networks);
      setAllOperators(operators)
      setFormValues({
        operator: matchedOperator?.network || '',
        locationDescription,
        name,
        description,
        latitude,
        longitude,
        city,
        indianState,
        // state,
        locationType,
        locationtag,
        size,
        restrictionLevel,
        parking: parkingLevel,
        available247,
        phoneNumber,
        amenities,
     
      });
     
      } catch (err) {
        setError('Failed to load store details');
      } finally {
        setLoading(false);
      }
    };

    fetchDetails();
  }, [storeDetails]);

  console.log("storedetails",storeDetails)
  if (!storeDetails) {
    return <p className="text-red-500">No store details provided.</p>;
  }

  const handleDropdownChange = (key, value) => {
    setFormValues((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handlePlaceSelect = (place) => {
    setSelectedPlace(place);
    setSearchQuery(place.description);
    setPlaceResults([]); 
  };
  
  
  
  const handleEditToggle = () => {
    setIsEditable((prev) => !prev);
    if (!isEditable) {
      setSearchQuery('');
      setSelectedPlace(null);
      setPlaceResults([]);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
     const selectedOperator = allOperators.find(
        (operator) => operator.network === formValues.operator
      );
  
     const  placeId=selectedPlace?.placeId||responsePlaceId;
     console.log(placeId)
      if (!placeId) {
        alert('Location description does not match any place suggestions!');
        return;
      }
      if (!selectedOperator) {
        alert('Operator not found!');
        return;
      }
  
      const operatorId = selectedOperator.operatorId; 
      console.log('Selected Operator ID:', operatorId);
  
      const payload = {
        operatorId,
        googlePlaceDetails: {
          placeId,
        },
        name: formValues?.name,
        description: formValues?.description,
        location: {
          latitude: formValues?.latitude,
          longitude: formValues?.longitude,
          city: formValues.city?.toUpperCase(),
          state: formValues.indianState?.toUpperCase(),
          addressLine1: formValues.locationDescription,
        },
        locationType: formValues.locationType?.toUpperCase(),
        locationTags: Array.isArray(formValues.locationtag)
          ? formValues.locationtag.map((tag) => tag.toUpperCase())
          : [formValues.locationtag?.toUpperCase()],
        size: formValues.size?.toUpperCase(),
        restrictionLevel: formValues.restrictionLevel?.toUpperCase(),
        parkingLevel: formValues.parking?.toUpperCase(),
        timings: {
          available247: formValues.available247,
        },
        contactInfo: {
          primarySupportNumber: {
            countryCode: 91,
            phoneNumber: formValues.phoneNumber,
          },
        },
        amenities: {
          amenityTypes: formValues.amenities,
        },
      };
  
      console.log('Updated Store Details Payload:', payload);
      const response = await updateStore(storeId, payload);
      console.log(response)
      alert('Store details updated successfully!');
      setIsEditable(false);
    } catch (error) {
      console.error('Error updating store:', error);
      alert('Failed to update store details. Please try again.');
    }
  };
  
  const dropdownKeyMapping = {
    locationType: 'locationTypes',
    parking: 'parkingLevels',
    restrictionLevel: 'restrictionLevels',
    size: 'sizes',
    locationtag: 'locationTags',
  };

  return (
    <div className="flex flex-col items-center p-6 max-w-md mx-auto bg-white shadow-md rounded-md">
      <h2 className="text-2xl font-semibold mb-4">Store Details</h2>
  
      {error && <p className="text-red-500 mt-4">{error}</p>}
  
      {storeDetails && (
        <div className="bg-white shadow-lg rounded-lg p-6 w-full mt-6">
          <button
            onClick={handleEditToggle}
            className="px-4 py-2 mb-4 bg-green-500 text-white rounded hover:bg-green-600"
          >
            {isEditable ? 'Cancel Edit' : 'Edit'}
          </button>

          {isEditable ? (
            <div key="locationDescription" className="mb-4">
              <label className="block text-gray-700 capitalize mb-2">
                Location Description
              </label>
  
              <div className="relative">
                <input
                  type="text"
                  value={searchQuery || formValues.locationDescription} 
                  onChange={(e) => setSearchQuery(e.target.value)} 
                  onFocus={() => setSearchQuery('')} 
                  placeholder="Enter location prompt"
                  className="w-full border rounded px-4 py-2"
                />
  
               
                {placeResults.length > 0 && searchQuery.trim() && (
                  <ul className="absolute left-0 right-0 bg-white border border-gray-300 mt-1 max-h-60 overflow-y-auto z-10">
                    {placeResults.map((place) => (
                      <li
                        key={place.place_id}
                        onClick={() => handlePlaceSelect(place)}
                        className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                      >
                        {place.description}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
          ) : (
            <div key="locationDescription" className="mb-4">
              <label className="block text-gray-700 capitalize mb-2">Location Description</label>
              <p className="text-gray-900">{formValues.locationDescription || 'N/A'}</p> 
            </div>
          )}

          {Object.entries(formValues).map(([key, value]) => {
            if (key === 'locationDescription') return null; 
            return (
              <div key={key} className="mb-4">
                <label className="block text-gray-700 capitalize mb-2">
                  {key.replace(/([A-Z])/g, ' $1')}
                </label>
  
              
                {dropdownKeyMapping[key] && dropdownData[dropdownKeyMapping[key]]?.length > 0 && isEditable ? (
                  <select
                    value={value}
                    onChange={(e) => handleDropdownChange(key, e.target.value)}
                    className="w-full border rounded px-4 py-2"
                  >
                    <option value="">Select {key.replace(/([A-Z])/g, ' $1')}</option>
                    {dropdownData[dropdownKeyMapping[key]].map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                ) : null}
  
                {/* Amenities Field */}
                {key === 'amenities' && isEditable ? (
                  <div className="flex flex-wrap">
                    {dropdownData.amenitiesOptions?.length > 0 ? (
                      dropdownData.amenitiesOptions.map((amenity) => (
                        <label key={amenity} className="flex items-center mr-4 mb-2">
                          <input
                            type="checkbox"
                            checked={value.includes(amenity)}
                            onChange={() => {
                              const updatedAmenities = value.includes(amenity)
                                ? value.filter((a) => a !== amenity)
                                : [...value, amenity];
                              handleDropdownChange(key, updatedAmenities);
                            }}
                            className="mr-2"
                          />
                          <span className="text-gray-700">{amenity}</span>
                        </label>
                      ))
                    ) : (
                      <span>Loading amenities...</span>
                    )}
                  </div>
                ) : null}
  
               
                {key === 'operator' && isEditable ? (
                  <div className="flex flex-wrap">
                    <select
                      value={value}
                      onChange={(e) => handleDropdownChange(key, e.target.value)}
                      className="w-full border rounded px-4 py-2"
                    >
                      <option value="">Select Operator</option>
                      {allNetworks?.map((operator) => (
                        <option key={operator} value={operator}>
                          {operator}
                        </option>
                      ))}
                    </select>
                  </div>
                ) : null}
  
              
                {key === 'available247' && isEditable ? (
                  <input
                    type="checkbox"
                    name={key}
                    checked={value}
                    onChange={(e) => handleDropdownChange(key, e.target.checked)}
                    className="mr-2"
                  />
                ) : null}
  
            
                {isEditable && !dropdownKeyMapping[key] && key !== 'amenities' && key !== 'operator' && key !== 'available247' ? (
                  <input
                    type="text"
                    value={value}
                    onChange={(e) => handleDropdownChange(key, e.target.value)}
                    className="w-full border rounded px-4 py-2"
                  />
                ) : null}
  
               
                {!isEditable && key !== 'locationDescription' ? (
                  <p className="text-gray-900">{Array.isArray(value) ? value.join(', ') : value || 'N/A'}</p>
                ) : null}
              </div>
            );
          })}
  
          {isEditable && (
            <button
              onClick={handleSubmit}
              className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
            >
              Save Changes
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default StoreList;
