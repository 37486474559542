import React, { useState } from 'react';
import StoreDetailsEditor from '../components/StoreDetailsEditor';
import { fetchStores, updateStore } from '../api';

const stateOptions = [
  { value: 'CLOSED', label: 'CLOSED' },
  { value: 'DISABLED', label: 'DISABLED' },
  { value: 'PENDING_REVIEW', label: 'PENDING_REVIEW' },
  { value: 'ENABLED', label: 'ENABLED' },
  { value: 'NOT_ACTIVATED', label: 'NOT_ACTIVATED' },
  { value: 'BLACKLISTED', label: 'BLACKLISTED' },
];

const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
    <div
      className="bg-white rounded-lg mx-4 w-full max-w-md sm:max-w-lg md:max-w-xl max-h-[90vh] overflow-y-auto"
    >
      <div className="p-4 sm:p-6">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl sm:text-2xl font-bold">Store Details</h2>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700 text-xl sm:text-2xl font-bold"
          >
            ×
          </button>
        </div>
        {children}
      </div>
    </div>
  </div>
  
  );
};

const InternalStoresSearch = () => {
  
  const [location, setLocation] = useState({ latitude: '', longitude: '', radiusInMeters: 50000 });
  const [states, setStates] = useState([]);
  const [stores, setStores] = useState([]);
  const [selectedStore, setSelectedStore] = useState(null);
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [pageSize, setPageSize] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);

  const handleLocationChange = (e) => {
    setLocation((prevLocation) => ({
      ...prevLocation,
      [e.target.name]: e.target.value,
    }));
  };

  if(setLoading){
    if(setCurrentPage){}
  }
  const handleStatesChange = (e) => {
    setStates(Array.from(e.target.selectedOptions, (option) => option.value));
  };

  const handleStoreDetails = (storeId) => {
    const store = stores.find((context) => context.store.storeId === storeId);
    if (store) {
      setSelectedStore(store);
      setIsDetailsOpen(true);
    } else {
      setErrorMessage('Store not found.');
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      const stores = await fetchStores(location, states, currentPage, pageSize);
      setStores(stores);
    } catch (error) {
      console.error('Error fetching stores:', error);
    }
  };

  const handleStoreUpdate = async (updatedStoreData) => {
    try {
      await updateStore(updatedStoreData.store.storeId, updatedStoreData);
      alert('Store updated successfully!');
    } catch (error) {
      console.error('Error updating store:', error);
      alert('An error occurred while updating the store.');
    }
  };

  return (
    <div className="p-6 w-full mx-auto bg-gray-50">
    <h1 className="text-xl sm:text-2xl font-bold mb-4 text-center">Internal Stores Search</h1>
    {errorMessage && <div className="text-red-500 mb-4">{errorMessage}</div>}
  
    <form onSubmit={handleFormSubmit} className="space-y-4">
      <div>
        <label htmlFor="latitude" className="block font-medium mb-1">
          Latitude
        </label>
        <input
          type="text"
          id="latitude"
          name="latitude"
          className="border rounded px-3 py-2 w-full"
          placeholder="Enter latitude"
          value={location.latitude}
          onChange={handleLocationChange}
        />
      </div>
      <div>
        <label htmlFor="longitude" className="block font-medium mb-1">
          Longitude
        </label>
        <input
          type="text"
          id="longitude"
          name="longitude"
          className="border rounded px-3 py-2 w-full"
          placeholder="Enter longitude"
          value={location.longitude}
          onChange={handleLocationChange}
        />
      </div>
      <div>
        <label htmlFor="states" className="block font-medium mb-1">
          States
        </label>
        <select
          id="states"
          name="states"
          multiple
          className="border rounded px-3 py-2 w-full"
          onChange={handleStatesChange}
          value={states}
        >
          {stateOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
      <div>
        <label htmlFor="pageSize" className="block font-medium mb-1">
          Page Size
        </label>
        <input
          type="number"
          id="pageSize"
          name="pageSize"
          className="border rounded px-3 py-2 w-full"
          placeholder="Enter page size"
          value={pageSize}
          onChange={(e) => setPageSize(Number(e.target.value))}
        />
      </div>
      <button
        type="submit"
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-full sm:w-auto"
        disabled={loading}
      >
        {loading ? 'Searching...' : 'Search'}
      </button>
    </form>
  
    {loading && <div>Loading...</div>}
  
    {stores.length > 0 && (
  <div className="mt-4 overflow-x-auto">
    <table className="table-fixed w-full border-collapse">
      <thead>
        <tr className="bg-gray-200">
          <th className="py-2 px-2 sm:px-4 text-left w-1/3">Store ID</th>
          <th className="py-2 px-2 sm:px-4 text-left w-1/3">Store Name</th>
          <th className="py-2 px-2 sm:px-4 text-left w-1/3">Details</th>
        </tr>
      </thead>
      <tbody>
        {stores.map((context, index) => (
          <tr key={index} className="border-b">
            <td className="py-2 px-2 sm:px-4 truncate text-sm sm:text-base">
              {context.store.storeId || 'N/A'}
            </td>
            <td className="py-2 px-2 sm:px-4 truncate text-sm sm:text-base">
              {context.store.name || 'N/A'}
            </td>
            <td className="py-2 px-2 sm:px-4">
              <button
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 sm:py-2 sm:px-4 rounded w-full sm:w-auto"
                onClick={() => handleStoreDetails(context.store.storeId)}
              >
                View Details
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
)}

  
    <Modal isOpen={isDetailsOpen} onClose={() => setIsDetailsOpen(false)}>
      {selectedStore ? (
        <StoreDetailsEditor
          data={selectedStore}
          onSave={handleStoreUpdate}
        />
      ) : (
        <div>Loading store details...</div>
      )}
    </Modal>
  </div>
  
  );
};

export default InternalStoresSearch;
